<template lang="pug">
  form.auth-form(@submit.prevent.stop="emitSave")
    .d-flex.flex-column.justify-content-start
      .auth-form-logo
      .auth-form-form
        .auth-form-form-header {{ title }}
        .auth-form-form-body
          slot(name="fields")
        .d-flex.justify-content-between.align-items-center.m-t-30
          .auth-form-form-link
            slot(name="link")
          slot(name="button")
            AppSaveButton(
              title="actions.confirm"
              @save="emitSave"
            )
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      }
    },

    components: {
      AppSaveButton: () => import("@/components/elements/AppButton/Save")
    },

    methods: {
      emitSave() {
        this.$emit("save")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .auth-form
    width: 100%
    display: flex
    justify-content: center
    padding-top: calc(100vh / 100 * 15)

    &-logo
      width: 100%
      height: 44px
      background: center / contain no-repeat url(~@/assets/images/metroconductor-white.svg)

    &-form
      font-weight: 300
      width: 500px
      background: $default-white
      margin-top: 50px
      padding: 30px
      border-radius: 5px

      &-header
        font-size: 1.5rem
        border-bottom: 1px solid $default-gray-light
        padding-bottom: 25px

      &-link
        font-size: 0.8rem
        text-decoration: underline
        a
          color: $default-purple
</style>
